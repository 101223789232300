import React from "react";
import Slider from "react-slick"; // Ensure you import Slider
import mov1 from "./assets/mov1.jpeg";
import mov2 from "./assets/e-ghost.jpg";
import mov4 from "./assets/mov3.jpg";
import mov5 from "./assets/mov4.jpeg";
import mov6 from "./assets/mov5.jpg";
import mov3 from './assets/Jackpot.jpg';
import "slick-carousel/slick/slick.css"; // Import styles
import "slick-carousel/slick/slick-theme.css"; // Import theme styles
import "./App.css"; // Import your CSS file

const movies = [
  { src: mov1, name: "Unlock Zindagi" },
  { src: mov2, name: "E-Ghost" },
  { src: mov3, name: "Jackpot" },
  { src: mov4, name: "Khavis" },
  { src: mov5, name: "Potra (2021)" },
  { src: mov6, name: "BhumigatKranti" },
];

const PortfolioSlider = () => {
  const settings = {
    dots: true, // Show dots for navigation
    infinite: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {movies.map((movie, index) => (
          <div className="carousel-item" key={index}>
            <div className="image-container">
              <img src={movie.src} alt={movie.name} />
              <div className="overlay">
                <span className="movie-name">{movie.name}</span>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default PortfolioSlider;
