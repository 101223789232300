import React, { useState, useRef, useEffect, useContext } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import logo from "./assets/logo.png";
import profileImage from "./assets/profile_Atharva.jpg";
import recording from "./assets/recording.jpg";
import dubbing from "./assets/dubbing.jpg";
import podcast from "./assets/podcast.jpg";
import voiceover from "./assets/voiceover.jpg";
import syncSound from "./assets/syncSound.jpg";
import storyTelling from "./assets/storyTelling.jpg";
import ImageCarousel from "../src/ImageCarousel"; // Import the carousel component
import "./App.css";
import emailjs from '@emailjs/browser';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  const [activeLink, setActiveLink] = useState("home");

  // State for contact form inputs
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  //const [snackbarOpen, setSnackbarOpen] = useState(false);
  const imageWrapperRef = useRef(null);
  const imageNames = [
    "Recording",
    "Dubbing",
    "Podcast",
    "Voiceover",
    "SyncSound",
    "StoryTelling",
  ];
  const images = [
    recording,
    dubbing,
    podcast,
    voiceover,
    syncSound,
    storyTelling,
  ];

  useEffect(() => {
    // Load EmailJS script
    const script = document.createElement('script');
    script.src = "https://cdn.jsdelivr.net/npm/emailjs-com@2.6.4/dist/email.min.js";
    script.async = true;
    document.body.appendChild(script);

    // Cleanup function to remove the script
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      index = (index + 1) % (images.length - 2); // Loop through images
      imageWrapperRef.current.style.transform = `translateX(-${index * (100 / 3)}%)`;
    }, 3000); // Change every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [images.length]);

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    //console.log(`Changing ${name} to ${value}`);
  };

  

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();


    emailjs.send("service_9cf2vq8", "template_yv7qkfj", formData ,'00YqCXld4yHvBqrNi')
    .then(
      () => {
        console.log("Success");
        toast.success("Message Sent !!!");
        
      },
      (error) => {
        console.log("Failed");
        toast.error("Error !!!");
      }
    );


    setFormData({
      name: "",
      email: "",
      phone: "",
      message: "",
    });

  };

  // const handleCloseSnackbar = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setSnackbarOpen(false);
  // };

  return (
    <div>
      <div className="background_Home" id="home">
        <nav className="navbar">
          <div className="navbar-brand">
            Audio <span className="axis-color">Axis</span>
          </div>
          <ul className="navbar-links">
            <li>
              <a
                href="#home"
                className={activeLink === "home" ? "active-link" : ""}
                onClick={() => handleLinkClick("home")}
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="#about"
                className={activeLink === "about" ? "active-link" : ""}
                onClick={() => handleLinkClick("about")}
              >
                About Us
              </a>
            </li>
            <li>
              <a
                href="#services"
                className={activeLink === "services" ? "active-link" : ""}
                onClick={() => handleLinkClick("services")}
              >
                Services
              </a>
            </li>
            <li>
              <a
                href="#portfolio"
                className={activeLink === "portfolio" ? "active-link" : ""}
                onClick={() => handleLinkClick("portfolio")}
              >
                Portfolio
              </a>
            </li>
            <li>
              <a
                href="#contactus"
                className={activeLink === "contactus" ? "active-link" : ""}
                onClick={() => handleLinkClick("contactus")}
              >
                Contact Us
              </a>
            </li>
          </ul>
        </nav>
        <div className="content-container">
          <div className="logo-container">
            <img src={logo} alt="Logo" className="logo" />
          </div>
          <div className="welcome-message">
            <h1 className="welcome-text">Welcome!!!</h1>
            <h2>
              Audio <span className="axis-color">Axis</span>
            </h2>
          </div>
        </div>
        <div className="button-container">
          <button
            className="custom-button"
            onClick={() => {
              setActiveLink("about"); // Set active link to "about"
              document
                .getElementById("about")
                .scrollIntoView({ behavior: "smooth" }); // Smooth scroll to the "About Us" section
            }}
          >
            Learn More
          </button>
          <button
            className="custom-button"
            onClick={() => {
              setActiveLink("contact"); // Set active link to contact
              document
                .getElementById("contactus")
                .scrollIntoView({ behavior: "smooth" }); // Smooth scroll to contact section
            }}
          >
            Contact
          </button>
        </div>
      </div>

      {/* About Us Section */}
      <section id="about" className="background_AboutUs">
        <div className="profile-image">
          <img src={profileImage} alt="Atharva" />
        </div>
        <div className="about-text">
          <h2>About Us</h2>
          <p>
            Welcome to AudioAxis, where every sound becomes a masterpiece.
            Specializing in recording, dubbing, voiceover, foley, and sync
            sound, we bring stories to life with an artistry that resonates
            beyond just hearing. Our team of passionate sound artists and
            engineers shapes every project with precision and creativity, making
            each soundscape unforgettable. Sound is our art, and each project is
            a masterpiece in the making. At AudioAxis, sound isn't just
            produced—it’s crafted to captivate, inspire, and leave an
            unforgettable impact.
          </p>
        </div>
      </section>
      <section id="services" className="background_Services">
        <div className="services-text">
          <h2>Our Services</h2>
        </div>
        <div className="image-slider">
          <div className="image-wrapper" ref={imageWrapperRef}>
            {images.map((img, index) => (
              <div className="image" key={index}>
                <img src={img} alt={`Service ${index + 1}`} />
                <div className="image-name">{imageNames[index]}</div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section id="portfolio" className="background_Portfolio">
        <div className="portfolio-text">
          <h2>Our Portfolio</h2>
          <ImageCarousel /> {/* Include the carousel here */}
        </div>
      </section>

      <section id="contactus" className="background_Contactus">
        <div className="contactus-text">
          <h2>Contact Us</h2>
        </div>
        <div className="contact-container">
          <div className="contact-info">
            <i className="fas fa-map-marker-alt"></i>
            <span className="address-title">Address:</span>
            <div className="dummy-address">
              Advait, 72/1, C/35, Samarth Nagar, New Sangavi, Pimpri-Chinchwad,
              Maharashtra 411027
            </div>
            <i className="fas fa-phone-alt"></i>
            <span className="call-title">Let's Talk:</span>
            <div className="dummy-phone-number">+91 7276113690</div>
            <i className="fas fa-envelope"></i>
            <span className="email-title">General Support:</span>
            <div className="dummy-email">info@audioaxis.in</div>
          </div>
          <div className="contact-box">
            <p className="contact-title">Send Us A Message</p>
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Name:</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Email:</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Phone:</label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Message:</label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button type="submit" className="submit-button">Send Message</button>
              <ToastContainer />
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
